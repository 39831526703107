import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useAwardsData = previewData => {
  const staticData = useStaticQuery(
    graphql`
      query {
        prismicAwardsPage {
          _previewable
          uid
          data {
            hero_image {
              alt
              fluid {
                aspectRatio
                sizes
                src
                srcSet
              }
            }
            body {
              ... on PrismicAwardsPageBodyHeader {
                primary {
                  title {
                    html
                  }
                  tagline {
                    text
                  }
                  text {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicAwardsPageBodyAwards {
                slice_type
                primary {
                  title {
                    text
                  }
                }
              }
              ... on PrismicAwardsPageBodyCertifications {
                  slice_type
                  primary {
                      title {
                          text
                      }
                  }
              }
              ... on PrismicAwardsPageBodyAccolades {
                slice_type
                primary {
                  section_title {
                    text
                  }
                  rating_icon {
                    alt
                    fixed(width: 18) {
                      src
                      srcSet
                      width
                      height
                    }
                  }
                }
                items {
                  accolade_excerpt {
                    text
                  }
                  accolade_text {
                    text
                    html
                  }
                  rating
                }
              }
              ... on PrismicAwardsPageBodyBook {
                slice_type
                primary {
                  text {
                    text
                  }
                  button_text
                  link {
                    url
                    link_type
                  }
                }
              }
            }
          }
        }
        allPrismicAward {
          nodes {
            uid
            last_publication_date
            tags
            data {
              award_giver {
                text
              }
              award_image {
                alt
                fixed(height: 100, width: 100) {
                  height
                  src
                  srcSet
                  width
                }
              }
              body {
                ... on PrismicAwardBodyAwardYear {
                  slice_type
                  primary {
                    award_year
                  }
                  items {
                    award_name {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const mergedData: any = mergePrismicPreviewData({
    staticData,
    previewData,
    strategy: "rootReplaceOrInsert",
  })
  const awards = []
  const certifications=[]
    // @ts-ignore
  mergedData.allPrismicAward.nodes.sort((a, b) =>  new Date(a.last_publication_date) + new Date(b.last_publication_date)).map(award => {
    if(award.tags[0] === 'award') {
      awards.push(award.data)
    } else {
      certifications.push(award.data)
    }

  })

  if (mergedData.prismicAward) {
    awards.push(mergedData.prismicAward.data)
  }
  const { uid, data } = mergedData.prismicAwardsPage
  const bookData = data.body.find(section => section.slice_type === "book")
  const sections = data.body.filter(section => section.slice_type !== "book")
  sections.find(section => {
    if (section.slice_type === "awards") {
      section.items = awards
    } else if (section.slice_type === "certifications") {
      section.items = certifications
    }
  })
  return {
    uid,
    image: data.hero_image,
    sections,
    bookData: bookData ? bookData.primary : undefined,
  }
}
